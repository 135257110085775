<template>
  <section>
    <v-container class="py-16 px-5">
      <v-row>
        <v-row justify="center">
          <h1 class="display-2 primary--text">คอร์สทั้งหมด</h1>
        </v-row>

        <div class="col-md-12 col-sm-12 col-xs-12 mt-3">
          <div
            class="row text-center"
            data-aos="zoom-in-up"
            data-aos-duration="1250"
            data-aos-once="true"
          >
            <div
              class="col-md-3 col-sm-6 col-xs-12"
              :key="pro.subjectId"
              v-for="pro in historyList"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card class="mx-auto" color="grey lighten-4" max-width="600">
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="200px"
                    :src="imgPath(pro.subjectImg, 'subject')"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          white
                          darken-2
                          v-card--reveal
                          display-3
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-btn v-if="hover" @click="productDetail(pro)" outlined
                          >ดูเพิ่มเติม</v-btn
                        >
                      </div>
                    </v-expand-transition>

                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-card-text class="text--primary">
                    <h3>
                      <a @click="productDetail(pro)">
                        <h3>{{ pro.subjectName }}</h3>
                      </a>
                    </h3>
                    <!-- <h3>{{ numberWithCommas(pro.subjectPrice) }}</h3> -->
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </div>
          <div class="text-center mt-12">
            <v-pagination
              v-model="page"
              :length="pages"
              @input="updatePage"
            ></v-pagination>
          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import "@/mixins/generalMixin";

export default {
  name: "subjectsection",
  data() {
    return {
      page: 1,
      pageSize: 8,
      range: [0, 10000],
      select: "ล่าสุด",
      options: ["ล่าสุด", "ราคา: น้อย - มาก", "ราคา: มาก - น้อย"],
      min: 0,
      max: 10000,
      items: [
        {
          id: 2,
          name: "หมวดภาษา",
          children: [
            { id: 2, name: "ภาษาญี่ปุ่น" },
            { id: 3, name: "ภาษาอังกฤษ" },
          ],
        },
        // {
        //   id: 1,
        //   name: "Clothing",
        //   children: [
        //     { id: 5, name: "Shirts" },
        //     { id: 6, name: "Tops" },
        //     { id: 7, name: "Tunics" },
        //     { id: 8, name: "Bodysuit" },
        //   ],
        // },
      ],
      products: [
        {
          id: 1,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 2,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 3,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 4,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 5,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 6,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 7,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
        {
          id: 8,
          name: "ภาษาญี่ปุ่นเริ่มต้น",
          type: "ภาษาญี่ปุ่น",
          price: "3,000 บาท",
          src: "https://s3.amazonaws.com/subjectsity-blog/2021/04/Japanese_Language_Classes.png",
        },
      ],
      heroAlt: [
        {
          src: "pexels-moose-photos-1036641.jpg",
          heading: "คอร์สเรียน",
        },
      ],
      listCount: 0,
      historyList: [],
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  methods: {
    productDetail(product) {
      this.$router.push({
        name: "Product",
        query: { subjectId: product.subjectId },
      });
    },

    initPage() {
      let _this = this;
      _this.listCount = _this.products.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.products;
      } else {
        _this.historyList = _this.products.slice(0, _this.pageSize);
      }
    },

    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.products.slice(_start, _end);
      _this.page = pageIndex;
    },

    getAllCourse() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/subject/getAllSubject.php`)
        .then((res) => {
          this.products = res.data;
          console.log("products", this.products);
          this.products = this.products.filter(
            (product) => product.subjectSta === 1
          );
          let _this = this;
          _this.initPage();
          _this.updatePage(_this.page);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAllCourse();
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>
