<template>
  <section :class="this.$vuetify.theme.dark ? '' : 'grey lighten-4'">
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" xl="10" lg="9" md="8" sm="8" class="py-16">
          <v-row>
            <v-col
              v-for="course in courses"
              :key="course.Id"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <v-card max-width="450" class="mx-auto" elevation="1">
                <!-- <v-img
                  class="white--text align-end"
                  height="200px"
                  src="https://s3.amazonaws.com/coursesity-blog/2021/04/Japanese_Language_Classes.png"
                >
                </v-img> -->
                <v-card-subtitle class="pb-0">
                  <v-btn href="#" text small color="primary" class="px-0"
                    >จำนวนรายวิชา {{ course.subject }}</v-btn
                  >
                </v-card-subtitle>
                <v-card-text
                  class="title font-weight-bold mt-3 pb-0 text--primary"
                  style="line-height: 1.8rem"
                >
                  {{ course.courseName }}
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    class="ml-auto"
                    color="primary"
                    right
                    @click="toSubject(course)"
                    >เลือกเรียน</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <div class="text-center">
            <div class="text-center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-container class="max-width">
                      <v-pagination
                        v-model="page"
                        circle
                        class="my-4"
                        :length="1"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="4" class="py-16">
          <aside>
            <v-text-field
              clearable
              dense
              flat
              outlined
              placeholder="Search..."
              append-icon="mdi-magnify"
              class="mb-6"
              hide-details
            >
            </v-text-field>
            <v-card outlined class="mb-6">
              <div
                class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                Categories
              </div>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <v-card outlined class="mb-6">
              <div
                class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                Tags
              </div>
              <v-card-text>
                <v-chip-group column>
                  <v-chip v-for="tag in tags" :key="tag">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
            <v-card outlined class="mb-6">
              <div
                class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                Recent Comments
              </div>

              <v-list three-line dense>
                <template v-for="(item, index) in comments">
                  <v-subheader
                    v-if="item.header"
                    :key="item.header"
                    v-text="item.header"
                  ></v-subheader>

                  <v-divider v-else-if="item.divider" :key="index"></v-divider>

                  <v-list-item v-else :key="item.title">
                    <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </aside>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "MyCourse",
  components: {
    HeroAlt,
  },
  data() {
    return {
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "คอร์สของฉัน",
        },
      ],
      courses: [],
      posts: [
        {
          id: "",
          title: "Hello World",
          postImage: "",
          publishedOn: "",
          lastUpdated: "",
          author: "",
          tags: "",
          category: "",
          excerpt: "",
          content: "",
        },
      ],
      page: 1,
      tags: [
        "Work",
        "Home Improvement",
        "Vacation",
        "Food",
        "Drawers",
        "Shopping",
        "Art",
        "Tech",
        "Creative Writing",
      ],
      items: [
        { text: "Technology" },
        { text: "Health" },
        { text: "News" },
        { text: "Recent Discovery" },
        { text: "Lifestyle and Fitness" },
        { text: "Fashion" },
        { text: "Music" },
      ],
      comments: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Ali Connors",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "Alex Scott",
          subtitle: `Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Sandra Adams",
          subtitle: "Do you have Paris recommendations? Have you ever been?",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Trevor Hansen",
          subtitle:
            "Have any ideas about what we should get Heidi for her birthday?",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Britta Holt",
          subtitle:
            "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        },
      ],
    };
  },
  methods: {
    toSubject(course) {
      this.$router.push({
        name: "MySubject",
        query: { courseId: course.courseId },
      });
    },

    getMyCourse() {
      const jsonData = JSON.stringify({
        stdId: this.$route.query.stdId,
      });

      this.$http
        .post(
          `${process.env.VUE_APP_API_PATH}/student/getAllStdCourse.php`,
          jsonData
        )
        .then((res) => {
          this.courses = res.data;
          this.courses.filter((course) => course.courseSta === 1);
          console.log("res", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getMyCourse();
  },
};
</script>
