<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
        <v-container class="py-16 px-5">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <v-card outlined>
                <v-card-title>หมวดหมู่</v-card-title>
                <v-divider></v-divider>
                <template>
                  <v-treeview
                    :items="items"
                    :open="[2]"
                    :active="[1]"
                    :selected-color="'#fff'"
                    activatable
                    open-on-click
                    dense
                  ></v-treeview>
                </template>
                <v-divider></v-divider>
              </v-card>
            </div>

            <div class="col-md-9 col-sm-9 col-xs-12">
              <v-row class="mb-1" dense>
                <v-col cols="12" sm="8" class="pl-6 pt-6">
                  <small>หลักสูตรทั้งหมด {{ products.length }}</small>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    class="pa-0"
                    v-model="select"
                    :items="options"
                    style="margin-bottom: -20px"
                    label="จัดเรียงโดย"
                    @change="productsFilter"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row align="stretch" class="text-center mt-1">
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  xs="12"
                  :key="pro.id"
                  v-for="pro in historyList"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                      color="grey lighten-4"
                      max-width="600"
                    >
                      <v-img
                        class="white--text align-end"
                        :aspect-ratio="16 / 9"
                        height="250px"
                        :src="imgPath(pro.subjectImg, 'subject')"
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="
                              d-flex
                              transition-fast-in-fast-out
                              white
                              darken-2
                              v-card--reveal
                              display-3
                              white--text
                            "
                            style="height: 100%"
                          >
                            <v-btn
                              v-if="hover"
                              @click="productDetail(pro)"
                              class=""
                              outlined
                              >ดูเพิ่มเติม</v-btn
                            >
                          </div>
                        </v-expand-transition>

                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-card-text class="text--primary">
                        <a @click="productDetail(pro)">
                          <h3>{{ pro.subjectName }}</h3>
                        </a>
                        <!-- <h3>{{ pro.subjectPrice }} บาท</h3> -->
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <div class="text-center mt-12">
                <v-pagination
                  v-model="page"
                  :length="pages"
                  @input="updatePage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "Course",
  components: {
    HeroAlt,
  },
  data() {
    return {
      page: 1,
      pageSize: 9,
      range: [0, 10000],
      select: "ล่าสุด",
      options: ["ล่าสุด", "ราคา: น้อย - มาก", "ราคา: มาก - น้อย"],
      min: 0,
      max: 10000,
      items: [
        {
          id: 2,
          name: "คอร์สหลัก",
          children: [{ id: 1, name: "ภาษาญี่ปุ่น" }],
        },
        // {
        //   id: 1,
        //   name: "Clothing",
        //   children: [
        //     { id: 5, name: "Shirts" },
        //     { id: 6, name: "Tops" },
        //     { id: 7, name: "Tunics" },
        //     { id: 8, name: "Bodysuit" },
        //   ],
        // },
      ],
      products: [],
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "คอร์สเรียน",
        },
      ],
      listCount: 0,
      historyList: [],
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  methods: {
    productsFilter() {
      if (this.select === "ล่าสุด") {
        this.getAllCourse();
      } else if (this.select === "ราคา: น้อย - มาก") {
        return this.products.sort((a, b) =>
          a.subjectPrice > b.subjectPrice ? 1 : -1
        );
      } else if (this.select === "ราคา: มาก - น้อย") {
        return this.products.sort((a, b) =>
          a.subjectPrice < b.subjectPrice ? 1 : -1
        );
      } else {
        return this.products;
      }
    },

    productDetail(product) {
      this.$router.push({
        name: "Product",
        query: { subjectId: product.subjectId },
      });
    },
    initPage() {
      let _this = this;
      _this.listCount = _this.products.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.products;
      } else {
        _this.historyList = _this.products.slice(0, _this.pageSize);
      }
    },

    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.products.slice(_start, _end);
      _this.page = pageIndex;
    },

    getAllCourse() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/subject/getAllSubject.php`)
        .then((res) => {
          this.products = res.data;
          this.products = this.products.filter(
            (product) => product.subjectSta === 1
          );
          console.log("products", this.products);
          let _this = this;
          _this.initPage();
          _this.updatePage(_this.page);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAllCourse();
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>
