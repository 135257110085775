import Vue from "vue";

Vue.mixin({
  methods: {
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    filePath(file, folder) {
      return `${process.env.VUE_APP_IMG_PATH}/${folder}/${file}`;
    },
    videoPath(video, folder) {
      return `${process.env.VUE_APP_IMG_PATH}/${folder}/${video}`;
    },
    imgPath(img, folder) {
      return `${process.env.VUE_APP_IMG_PATH}/${folder}/${img}`;
    },
    toThaiDateString(date) {
      let monthNames = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม.",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      let year = date.getFullYear() + 543;
      let month = monthNames[date.getMonth()];
      let numOfDay = date.getDate();

      let hour = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let second = date.getSeconds().toString().padStart(2, "0");

      return (
        `${numOfDay} ${month} ${year} ` + `${hour}:${minutes}:${second} น.`
      );
    },
    async get(path) {
      const data = await this.$http
        .get(`${process.env.VUE_APP_API_PATH}${path}`)
        .catch((err) => console.log(err));
      return data.data;
    },
    async post(path, formData) {
      const data = await this.$http
        .post(`${process.env.VUE_APP_API_PATH}${path}`, formData)
        .catch((err) => console.log(err));
      return data;
    },
  },
});
