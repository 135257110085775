<template>
  <l-map
    style="height: 500px; width: 100%; display: none"
    v-if="showMap"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="withTooltip">
      <l-tooltip :options="{ permanent: true, interactive: true }">
        <div>Here!</div>
      </l-tooltip>
    </l-marker>
  </l-map>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    // LTooltip,
  },
  data() {
    return {
      mapData: {
        lat: null,
        long: null,
      },
      zoom: 20,
      center: latLng(18.7710881, 98.9730403),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withTooltip: [1, 1],
      currentZoom: 11.5,
      currentCenter: [1, 1],
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    async getContact() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/manage/getContact.php`)
        .then((res) => {
          this.mapData = res.data[0];
          this.withTooltip = [res.data[0].latitude, res.data[0].longitude];
          this.currentCenter = [res.data[0].latitude, res.data[0].longitude];
          this.$emit("getContent", res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async created() {
    await this.getContact();
  },
};
</script>
