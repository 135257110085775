import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/components/Layout/Layout";

import Index from "@/views/Index";
import About from "@/views/About";
import Course from "@/views/Course";
import Product from "@/views/Product";
import MyCourse from "@/views/MyCourse";
import MySubject from "@/views/MySubject";
import MyLesson from "@/views/MyLesson";
import Contact from "@/views/Contact";
import ViewCourse from "@/views/ViewCourse";
import DoQuiz from "@/views/DoQuiz";
import DoTest from "@/views/DoTest";
import Login from "@/views/Login/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "Index",
        component: Index,
      },
      {
        path: "about-us",
        name: "About",
        component: About,
      },
      {
        path: "course",
        name: "Course",
        component: Course,
      },
      {
        path: "product",
        name: "Product",
        component: Product,
      },
      {
        path: "contact",
        name: "Contact",
        component: Contact,
      },
      {
        path: "my-course",
        name: "MyCourse",
        component: MyCourse,
      },
      {
        path: "my-subject",
        name: "MySubject",
        component: MySubject,
      },
      {
        path: "my-lesson",
        name: "MyLesson",
        component: MyLesson,
      },
      {
        path: "view-course",
        name: "ViewCourse",
        component: ViewCourse,
      },
      {
        path: "do-quiz",
        name: "DoQuiz",
        component: DoQuiz,
      },
      {
        path: "do-test",
        name: "DoTest",
        component: DoTest,
        beforeEnter: (to, from, next) => {
          let isTest = JSON.parse(localStorage.getItem("isTest"));
          console.log(isTest);
          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let stdId = JSON.parse(localStorage.getItem("stdId"));

  if (to.name === "MySubject") {
    if (!stdId) {
      next({ name: "Login" });
    }
  }

  // console.log(to, from);
  window.scrollTo(0, 0);
  next();
});

export default router;
