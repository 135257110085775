<template>
  <div class="mt-4">
    <v-container class="my-3">
      <div class="row">
        <div class="col-md-5 col-sm-5 col-xs-12">
          <v-carousel>
            <v-carousel-item :src="imgPath(subject.subjectImg, 'subject')">
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="col-md-7 col-sm-7 col-xs-12">
          <div class="pl-6">
            <h1 class="mb-0">{{ subject.subjectName }}</h1>
            <v-card-actions class="pa-0">
              <!-- <h2 class="font-weight-light pt-3 primary--text">
                ราคา {{ numberWithCommas(subject.subjectPrice) }} บาท
              </h2> -->
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-tabs class="mt-3">
              <v-tab>รายละเอียดหลักสูตร</v-tab>
              <v-tab-item>
                <p class="pt-5 font-weight-thin">
                  {{ subject.subjectDesc }}
                </p>
                <h3 class="info--text mt-3">
                  จำนวนบทเรียนทั้งหมด
                  <span class="black--text">
                    {{ subject.totalLesson }} บทเรียน</span
                  >
                </h3>
                <h3 class="info--text mt-1">
                  จำนวนชั่วโมงต่อบทเรียน
                  <span class="black--text"
                    >{{ subject.hourLesson }} ชั่วโมง</span
                  >
                </h3>
                <h3 class="info--text mt-1">
                  จำนวนชั่วโมงเรียนทั้งหมด
                  <span class="black--text"
                    >{{ subject.totalHour }} ชั่วโมง</span
                  >
                </h3>
                <h3 class="info--text mt-1">
                  ราคา
                  <span class="black--text">
                    {{ numberWithCommas(subject.subjectPrice) }} บาท</span
                  >
                </h3>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12">
          <v-tabs>
            <v-tab>Description</v-tab>
            <v-tab>Materials</v-tab>
            <v-tab>REVIEWS</v-tab>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ultricies mi eget mauris pharetra et. Vel pretium lectus quam id
                leo in vitae turpis massa. Orci dapibus ultrices in iaculis
                nunc. At auctor urna nunc id cursus metus. Integer feugiat
                scelerisque varius morbi enim nunc. Aliquam sem et tortor
                consequat id porta nibh venenatis cras. Pellentesque pulvinar
                pellentesque habitant morbi tristique senectus et netus.
                Malesuada nunc vel risus commodo viverra maecenas. Neque
                volutpat ac tincidunt vitae semper quis.
              </p>
            </v-tab-item>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ultricies mi eget mauris pharetra et. Vel pretium lectus quam id
                leo in vitae turpis massa. Orci dapibus ultrices in iaculis
                nunc. At auctor urna nunc id cursus metus. Integer feugiat
                scelerisque varius morbi enim nunc. Aliquam sem et tortor
                consequat id porta nibh venenatis cras. Pellentesque pulvinar
                pellentesque habitant morbi tristique senectus et netus.
                Malesuada nunc vel risus commodo viverra maecenas. Neque
                volutpat ac tincidunt vitae semper quis.
              </p>
            </v-tab-item>
            <v-tab-item>
              <v-list three-line="true" avatar="true">
                <v-list-item-group v-model="item" color="primary">
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    inactive="true"
                  >
                    <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title"></v-list-item-title
                      ><v-rating
                        v-model="rating"
                        class=""
                        background-color="warning lighten-3"
                        color="warning"
                        dense
                      ></v-rating>
                      <v-list-item-subtitle
                        v-html="item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs>
        </div>
      </div> -->
    </v-container>
  </div>
</template>
<script>
import "@/mixins/generalMixin";

export default {
  name: "Product",
  data: () => ({
    rating: 4.5,
    item: 5,
    subject: {},
  }),
  methods: {
    getSubject() {
      const jsonData = JSON.stringify({
        subjectId: this.$route.query.subjectId,
      });
      const data = this.post("/subject/getSubject.php", jsonData);
      data.then((res) => {
        this.subject = res.data[0];
        console.log("subject", this.subject);
      });
    },
  },
  created() {
    this.getSubject();
    console.log(this.$route.query);
  },
};
</script>
