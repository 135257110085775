<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group v-for="(item, i) in items" :key="i" color="primary">
          <v-list-item v-if="!item.submenu" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title.toUpperCase()" />
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title.toUpperCase()"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.submenu"
              :key="child.title"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app hide-on-scroll height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />
      <router-link
        to="/"
        class="d-flex primary--text"
        style="text-decoration: none"
      >
        <h3>{{ title }}</h3>
      </router-link>
      <v-spacer />

      <template v-for="(name, menuitem) in items">
        <template v-if="name.submenu">
          <v-menu
            :key="menuitem"
            open-on-hover
            offset-y
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                class="py-8 submenubtn hidden-sm-and-down"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ name.title }}
                <v-icon right small class="mx-0"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in name.submenu"
                :key="index"
                link
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-else
          :key="menuitem"
          depressed
          tile
          plain
          class="py-8 hidden-sm-and-down"
          :to="name.to"
          >{{ name.title }}</v-btn
        >
      </template>

      <v-spacer />
      <v-btn
        class="mr-2"
        outlined
        color="primary"
        @click="toMyCourse"
        v-if="isUser"
      >
        คอร์สของฉัน
      </v-btn>
      <v-btn color="primary" to="/login" v-if="!isUser"> เข้าสู่ระบบ </v-btn>
      <v-btn color="error" @click="logout" v-if="isUser"> ออกจากระบบ </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "siteHeader",
  props: ["title"],
  data() {
    return {
      isUser: false,
      studentData: {},
      clipped: false,
      drawer: false,
      items: [
        {
          icon: "mdi-folder-home-outline",
          title: "หน้าแรก",
          to: "/",
        },
        {
          icon: "mdi-folder-image",
          title: "คอร์สเรียน",
          to: "/course",
        },
        {
          icon: "mdi-account",
          title: "เกี่ยวกับเรา",
          to: "/about-us",
        },
        {
          icon: "mdi-contacts",
          title: "ติดต่อเรา",
          to: "/contact",
        },
      ],
    };
  },
  methods: {
    logout() {
      let formData = new FormData();
      formData.append("stdId", this.studentData.stdId);

      this.$http
        .post(`${process.env.VUE_APP_API_PATH}/authen/userLogout.php`, formData)
        .then((res) => {
          console.log(res);
          localStorage.setItem("studentData", "");
          localStorage.setItem("isUser", false);
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toMyCourse() {
      this.$router.push({
        name: "MySubject",
      });
    },
  },
  created() {
    this.studentData = JSON.parse(localStorage.getItem("studentData"));
    this.isUser = localStorage.getItem("isUser");
    console.log("studentData", this.studentData);
  },
};
</script>

<style scoped>
.submenubtn {
  cursor: default;
}
</style>
