<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
        <v-container class="px-5">
          <v-row class="py-16 px-5">
            <v-col v-html="content"></v-col>
          </v-row>
        </v-container>
        <Location @getContent="getContent" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Location from "@/components/Map/Location.vue";
import HeroAlt from "@/components/Sections/HeroAlt.vue";
export default {
  components: {
    HeroAlt,
    Location,
  },
  data() {
    return {
      content: "",
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "ติดต่อเรา",
        },
      ],
    };
  },
  head() {
    return {
      mapData: {},
      title: "Contact Us",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
  methods: {
    getContent(data) {
      this.content = data.content;
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style scoped>
#footer-top {
  display: none !important;
}
</style>
