<template>
  <section id="hero">
    <v-carousel
      :height="$vuetify.breakpoint.xs ? '30vh' : '90vh'"
      dark
      cycle
      show-arrows-on-hover
      draggable="true"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(carousel, carindex) in carouselsData"
        :key="carindex"
        :src="imgPath(carousel.slideImg, 'slide')"
      >
        <!-- <v-img
          :src="imgPath(carousel.slideImg, 'slide')"
          aspect-ratio="1.7"
        ></v-img> -->
        <v-container fill-height class="ml-3">
          <div style="max-width: 800px">
            <div
              class="
                text-md-h2
                mb-3
                white--text
                text-sm-h3 text-h5
                font-weight-black
              "
            >
              {{ carousel.slideTitle.toUpperCase() }}
            </div>
            <p class="mb-5 white--text">{{ carousel.slideDesc }}</p>
          </div>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import "@/mixins/generalMixin";

export default {
  name: "Hero",
  data() {
    return {
      carouselsData: [],
    };
  },
  methods: {
    getAllSlide() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/slide/getAllSlide.php`)
        .then((res) => {
          this.carouselsData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAllSlide();
  },
};
</script>

<style>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(3, 12, 41, 0.75),
    rgba(5, 11, 31, 0.65)
  );
}
</style>
