<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
        <v-container class="px-5">
          <v-row class="py-16">
            <v-col cols="12" v-html="content"> </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "About",
  components: {
    HeroAlt,
  },
  data() {
    return {
      content: "",
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "เกี่ยวกับเรา",
        },
      ],
    };
  },
  methods: {
    getAbout() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/manage/getAbout.php`)
        .then((res) => {
          this.content = res.data[0].content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async created() {
    this.getAbout();
  },
};
</script>
