<template>
  <v-app dark>
    <site-header :title="title"></site-header>
    <v-main>
      <router-view></router-view>
      <footer-top :footerForm="footerForm"></footer-top>
    </v-main>
    <site-footer :title="title"></site-footer>
  </v-app>
</template>

<script>
import FooterTop from "../footerTop.vue";
import SiteFooter from "../siteFooter.vue";
import SiteHeader from "../siteHeader.vue";

export default {
  name: "Layout",
  data() {
    return {
      title: "",
      footerForm: null,
    };
  },
  components: {
    SiteHeader,
    SiteFooter,
    FooterTop,
  },
  methods: {
    getTitle() {
      this.isLoading = true;

      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/manage/getTitle.php`)
        .then((res) => {
          this.title = res.data[0].title;
          this.footerForm = res.data[0];
          console.log(this.title);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.getTitle();
  },
};
</script>
