<template>
  <div>
    <section id="home">
      <v-row no-gutters>
        <v-col cols="12">
          <hero></hero>
          <course-section></course-section>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/Sections/Hero";
import CourseSection from "@/components/Sections/CourseSection";

export default {
  name: "Index",
  components: {
    CourseSection,
    Hero,
  },
  methods: {},
  created() {},
};
</script>
