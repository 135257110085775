<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$http
      .get(`${process.env.VUE_APP_API_PATH}/slide/getAllSlide.php`)
      .then((res) => {
        console.log(res);
        localStorage.setItem("slideImg", res.data[0].slideImg);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
